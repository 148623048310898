<template>
  <div>
    <div :class="classInput" class="justify-around">
      <div>
        <select class="input-form flex text-center" :id="idYear" v-model="yearValue" style="cursor:pointer !important;">
          <option value="AAAA" selected hidden>AAAA</option>
          <option :value="year" v-for="(year, i) in years" :key="i" :id="`${concatIdYear}_opt_${i}`">{{year}}</option>
        </select>
      </div>
      <div>
        <label for="" class="label-input text-center" style="font-size:16px !important;">|</label>
      </div>
      <div>
        <select class="input-form text-center" :id="idMonth" v-model="monthValue" :disabled="yearValue == 'AAAA'" style="cursor:pointer !important;">
          <option :value="monthValue" selected hidden>{{monthValue}}</option>
          <option :value="month" v-for="(month, i) in months" :key="i" :id="`${concatIdMonth}_opt_${i}`">{{month}}</option>
        </select>
      </div>
      <div>
        <label for="" class="label-input" style="font-size:16px !important;">|</label>
      </div>
      <div>        
        <select class="input-form flex text-center" :id="idDay" v-model="dayValue" v-on:change="changeDay()" :disabled="monthValue == 'MM'" style="cursor:pointer !important;">
          <option value="DD" selected hidden>DD</option>
          <option :value="day" v-for="(day, i) in days" :key="i" :id="`${concatIdDay}_opt_${i}`">{{day}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import enums from '@/helpers/enums'
import { getDaysByYearAndMonth, getMonthsOfYearInLetters, getRangeOfYears, 
          createDateStringByYearMonthDay } from '@/helpers/dates'
import moment from "moment";

export default {
  data() {
    return {
      concatIdYear: "",
      concatIdMonth: "",
      concatIdDay: "",
      years: [],
      months: [],
      days: [],
      dayValue: this.day,
      yearValue: this.year,
      monthValue: this.month
    } 
  },
  props: {
    description: {
      type: String,
      default: ''
    },
    day: {
      type: String,
      default: 'DD'
    },
    month: {
      type: String,
      default: 'MM'
    },
    year: {
      type: String,
      default: 'AAAA'
    },
    disableControls: {
      type: Boolean,
      default: false
    },
    classInput: {
      type: String,
      default: 'flex shadow-md border-2 rounded-lg px-2 py-2 w-full '
    },
    idYear: {
      type: String,
      default: ''
    },
    idMonth: {
      type: String,
      default: ''
    },
    idDay: {
      type: String,
      default: ''
    },
    isRemaining: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  watch: {
    yearValue(val) {
      this.generateMonthsAndDays(val, this.monthValue, "year");
    },
    monthValue(val) {
      this.generateMonthsAndDays(this.yearValue, val, "month");
    }
  },
  mounted() {
    this.concatIdYear = this.idYear;
    this.concatIdMonth = this.idMonth;
    this.concatIdDay = this.idDay;
    this.getYears();

    if(this.yearValue != "" && this.monthValue !="" && moment(`${this.year}`).isValid()){
      this.months = getMonthsOfYearInLetters(this.yearValue, this.isRemaining);
      const totalDays = Array.from(Array(moment(`${this.year}-${this.month}`, 'YYYY-MM').daysInMonth()), (_, i) => (i + 1 < 10)? `0${i + 1}` : `${i + 1}`);
      this.days = totalDays;
    }else{
      this.yearValue = 'AAAA';
      this.monthValue = 'MM';
      this.dayValue = 'DD';
    }
  },
  methods: {
    generateMonthsAndDays(year, month, dropDownSelected) {
      if (year != "AAAA" && dropDownSelected == "year") {
        this.months = getMonthsOfYearInLetters(year, this.isRemaining);
      }

      if (year != "AAAA" && month != "MM") {
        this.days = getDaysByYearAndMonth(year, enums.numericMonths[month], this.isRemaining);
      }

      if (this.dayValue != "DD") {
        if (!this.days.includes(this.dayValue)) {
          this.dayValue = "DD";
          this.emitEvent("");
        } else {
          this.emitEvent(this.getCompleteDateSelected(this.yearValue, this.monthValue, this.dayValue));
        }
      } else {
        this.emitEvent("");
      }

      if (this.monthValue != "MM") {
        if (!this.months.includes(this.monthValue)) {
          this.monthValue = "MM";
          this.dayValue = "DD";
        }
      }
    },
    getYears() {
      this.years = getRangeOfYears(100);
    },
    changeDay() {
      this.emitEvent(this.getCompleteDateSelected(this.yearValue, this.monthValue, this.dayValue));
    },
    emitEvent(value) {
      this.$emit('get-complete-date-selected', value);
    },
    getCompleteDateSelected(year, month, day) {
      return createDateStringByYearMonthDay(year, enums.numericMonths[month], day);
    },
  }
}
</script>


<style scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background-color: #fff;
}
select:focus {
  outline: none !important;
}
select[disabled] {
   color: #000;
}
.table {
  width: 0px !important;
  margin-bottom: 0px !important;
}
</style>